<template>
	<section class="projectsContainer container">
		<!-- <slot></slot> -->
		<h1 class="pageTitleh2 h2">
				{{$t('ultimos_projetos')}}
			</h1>

		<div class="row">
			<router-link
				v-for="(singleprojects, index) in projects"
				:key="singleprojects.id"
				:to="getprojectsLink(index)"
				class="col-12  projectsBlock animated slideInUp"
			>
				<div class="projectsContentIimage">
					<img
						:src="singleprojects.gallery[0].src"
						class="img-fluid"
						:alt="singleprojects.gallery[0].alt"
						width="260px"
						height="260px"
					/>
				</div>

				<div class="projectsContentText col-12">
					<div class="text">						
						<!-- <p class="projectsDate">{{ singleprojects.pubdata }}</p> -->
						<p class="projectsTitle" v-html="singleprojects.title"></p>
					</div>
				</div>
			</router-link>
		</div>

		<a
			@click="loadMoreClick"
			v-if="hasPaging && hasLink"
			style="display: block; text-align: center; margin: 30px 0 200px;"
		>
			<seeMoreButton>{{ $t("actions_seemore") }}</seeMoreButton>
		</a>

		<router-link
			to="/b-project"
			v-if="!hasPaging && hasLink"
			style="display: block; text-align: center; margin: 30px 0 200px;"
		>
			<seeMoreButton>{{ $t("actions_loadmore") }}</seeMoreButton>
		</router-link>
	</section>
</template>

<script>
	import seeMoreButton from "@/components/ui/seeMoreButton";

	export default {
		components: {
			seeMoreButton
		},
		data() {
			return {
				projects: [],
				fullprojects: [],
				currentPage: 1,
				itemsPerPage: 3,
				hasPaging: true,
				hasLink: true
			};
		},
		methods: {
			loadMoreClick() {
				let slice = this.fullprojects.slice(
					this.currentPage * this.itemsPerPage,
					(this.currentPage + 1) * this.itemsPerPage
				);
				this.projects = this.projects.concat(slice);
				this.currentPage++;

				
				if (
					Math.round(this.fullprojects.length / this.itemsPerPage) == this.currentPage
				)
					this.hasLink = false;
			},
			getprojectsLink: function(index) {
				return (
					"/b-project/" +
					this.projects[index].id +
					"-" +
					this.projects[index].title.replace(/\s/g, "_")
				);
			}
		},
		created() {
			this.itemsPerPage = this.$parent.projectsAmount;
			this.hasPaging = this.$parent.hasPaging;

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" + this.$i18n.locale + "/projetcs-list"
				)
				.then(response => {
					this.fullprojects = response.data;
					this.projects = this.fullprojects.slice(0, this.itemsPerPage);

					this.$eventBus.$emit("componentFinishLoad", true);
				});
		}
	};
</script>

<style lang="scss">

	@media (min-width: 1200px){
		.container-xl, .container-lg, .container-md, .container-sm, .projectsContainer.container {
			max-width: 1140px;
		}
	}
	.projectsContainer {
		z-index: 0;
		padding: 0 50px;

		.row {
			display: flex;
			justify-content: space-around;

			.projectsBlock {
				position: relative;
				height: 520px;
				padding: 0 5%;
				margin-bottom: 8%;

				& .projectsContentIimage {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
					width: 75%;
					height: 400px;
    				
					overflow: hidden;

					img {

						/*filter: grayscale(100%);
						-webkit-filter: grayscale(100%);*/
						-webkit-transition: all 1s ease;

						width: 100%;
						height: 100%;
						object-fit: cover;

						will-change: transform;
						-webkit-transition: transform 0.4s cubic-bezier(0, 0.5, 0, 1);
						-moz-transition: transform 0.4s cubic-bezier(0, 0.5, 0, 1);
						-o-transition: transform 0.4s cubic-bezier(0, 0.5, 0, 1);
						transition: transform 0.4s cubic-bezier(0, 0.5, 0, 1);
					}
				}

				& .projectsContentText {
					position: relative;
					float: right;
					width: 500px;
					margin-top: 150px;

					.text {
						position: absolute;
						top: 100px;
						right: 0;
						z-index: 2;
						width: 500px;
						height: 260px;
						padding: 60px 55px;
						background: #fff;
						-webkit-clip-path: polygon(
							156px 0,
							0 0,
							0 379px,
							260px 379px,
							260px 61px
						);
						-o-clip-path: polygon(390px 0, 0 0, 0 379px, 490px 379px, 490px 61px);
						-ms-clip-path: polygon(
							390px 0,
							0 0,
							0 379px,
							260px 379px,
							260px 61px
						);
						clip-path: polygon(390px 0, 0 0, 0 379px, 490px 379px, 490px 61px);

						.projectsTitle {
							font-family: 'Oswald';
							font-size: 38px;
							color: #000;
							line-height: 1.2;
							font-weight: 400;
						}
					}

					&::after {
						content: "";
						position: absolute;
						display: block;
						top: 97px;
						right: 0;
						z-index: 2;
						width: 110px;
						height: 460px;

						background: #fff;
						-webkit-transition: all 0.4s cubic-bezier(0, 0.5, 0, 1);
						-moz-transition: all 0.4s cubic-bezier(0, 0.5, 0, 1);
						-o-transition: all 0.4s cubic-bezier(0, 0.5, 0, 1);
						transition: all 0.4s cubic-bezier(0, 0.5, 0, 1);

						-webkit-clip-path: polygon(100px 2px, 0px 2px, 100px 63px);
						-o-clip-path: polygon(100px 2px, 0px 2px, 100px 63px);
						-ms-clip-path: polygon(100px 2px, 0px 2px, 100px 63px);
						clip-path: polygon(100px 2px, 0px 2px, 100px 63px);
					}
				}

				&:hover {
					cursor: pointer;

					.projectsContentIimage img {
						-webkit-transform: scale(1.1);
						-moz-transform: scale(1.1);
						-o-transform: scale(1.1);
						transform: scale(1.1);

						filter: grayscale(0%);
						filter: gray;
						-webkit-filter: grayscale(0%);
						filter: none;
						transition: 1s ease;
					}

					.projectsContentText::after {
						-webkit-clip-path: polygon(95px 8px, 17px 6px, 102px 58px);
						-o-clip-path: polygon(95px 8px, 17px 6px, 102px 58px);
						-ms-clip-path: polygon(95px 8px, 17px 6px, 102px 58px);
						clip-path: polygon(95px 8px, 17px 6px, 102px 58px);
					}
				}

				&:nth-child(2):hover .projectsContentText:nth-child(2)::after {
					-webkit-clip-path: polygon(105px 0, 0 0, 90px 50px);
					-o-clip-path: polygon(105px 0, 0 0, 90px 50px);
					-ms-clip-path: polygon(105px 0, 0 0, 90px 50px);
					clip-path: polygon(105px 0, 0 0, 90px 50px);
				}

				&:nth-child(3):hover .projectsContentText:nth-child(2)::after {
					-webkit-clip-path: polygon(110px 2px, 25px 10px, 95px 55px);
					-o-clip-path: polygon(110px 2px, 25px 10px, 95px 55px);
					-ms-clip-path: polygon(110px 2px, 25px 10px, 95px 55px);
					clip-path: polygon(110px 2px, 25px 10px, 95px 55px);
				}
			}
		}
	}

	.projectsContainer .projectsDate {
		// position: absolute;
		//bottom: 30px;
		font-family: "Oswald", sans-serif;
		font-size: 13px;
		font-weight: 400;
		font-style: normal;
		color: #575757;
	}
	#homepage .projectsContainer {
		padding: 0 5%;
	}

	@media (max-width: 812px) {
		.projectsContainer {
			padding: 0 20px 0 45px;
		}
	}
	@media (max-width: 768px) {
		.projectsContainer {
			padding: 0 20px 0 45px;
		}
		.projectsContainer .row .projectsBlock .projectsContentText .text .projectsTitle{
			font-size: 22px;
		}
		.projectsContainer .row .projectsBlock .projectsContentText .text{
			width: 350px;
			clip-path: polygon(240px 0, 0 0, 0 379px, 340px 379px, 340px 61px);
		}
	}
</style>
